import React from "react"
import classNames from "classnames"
import { Grid, Hidden, Box } from "@material-ui/core"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { RichTextField } from "../../molecules/Fields/RichTextField"
import { FooterData } from "../../../../types/pages"
import CustomTypography from "../../atoms/CustomTypography"
import { LinkField } from "../../molecules/Fields"
import CustomContainer from "../../atoms/CustomContainer"

interface Props {
  backgroundColor?: string
  transparent?: boolean
  data: FooterData
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    background: ({ backgroundColor }: any) => backgroundColor,
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    color: theme.palette.common.white,

    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  transparent: {
    position: "absolute",
    bottom: 0,
    background: "transparent",
  },
  wigoLogoContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
  },
  wigoLogo: {
    width: "59px",
    height: "31px",
  },
  footerLogo: {
    width: " 55px",
    padding: "0 20px",
  },
  link: {
    fontSize: "20px",
    letterSpacing: "0px",
    color: theme.palette.common.white,
    textDecoration: "none",
    fontWeight: "lighter",

    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(6),
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
    },
  },
  linksContainer: {
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  companyName: {
    fontWeight: 300,
  },
  companyInfo: {
    "& p": {
      fontWeight: 100,
    },
  },
  internalLinks: {
    textAlign: "right",
  },
  bottomHalf: {
    marginTop: "90px",

    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
  },
  dash: {
    width: "31px",
    height: "0px",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: "10px",
  },
  copyrightContainer: {
    paddingTop: theme.spacing(3),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  copyrightContainerMobile: {
    paddingTop: theme.spacing(1),
  },
  socialLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      paddingRight: theme.spacing(4),
    },
  },
  footerCopyright: {
    color: "#fff",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  footerCopyrightYear: {
    fontSize: "12px",
  },
  footerCopyrightText: {
    fontSize: "12px",
  },
}))

const SocialLinks = ({ classes, socialLinks }: any) => {
  return (
    <div className={classes.socialLinks}>
      {socialLinks.map((link: any, index: number) => (
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
        >
          <img className={classes.footerLogo} src={link.icon.fluid.base64} />
        </a>
      ))}
    </div>
  )
}

const Copyright = ({ classes, company }: any) => {
  return (
    <div className={classes.footerCopyright}>
      <CustomTypography mr={0.5} className={classes.footerCopyrightYear}>
        © {new Date().getFullYear()}
      </CustomTypography>
      <RichTextField
        value={company.copyright}
        className={classes.footerCopyrightText}
      />
    </div>
  )
}

const FooterLinks = ({ classes, linkItems }: any) => {
  return (
    <div className={classes.linksContainer}>
      {linkItems.map((item: any, index: number) => (
        <Box key={index} className={classes.link}>
          <LinkField value={item.link}>{item.label}</LinkField>
        </Box>
      ))}
    </div>
  )
}

const Footer = ({ transparent, backgroundColor, data }: Props) => {
  const { linkSection, company, social } = data
  const classes = useStyles({ backgroundColor })

  return (
    <div
      className={classNames(classes.root, {
        [classes.transparent]: transparent,
      })}
    >
      <CustomContainer>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.wigoLogoContainer}>
            <img src={company.logo.fluid.base64} className={classes.wigoLogo} />
          </Grid>
          <Grid item xs={12} md={8} className={classes.internalLinks}>
            <FooterLinks classes={classes} linkItems={linkSection.items} />
          </Grid>
          <Hidden mdUp>
            <Grid item xs={12}>
              <Box mt={6} mb={1}>
                <SocialLinks classes={classes} socialLinks={social.links} />
              </Box>
            </Grid>
          </Hidden>
        </Grid>

        <div className={classes.bottomHalf}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <div className={classes.dash} />
              <RichTextField
                variant="body2"
                value={company.name}
                className={classes.companyName}
              />
              <RichTextField
                variant="body2"
                value={company.info}
                className={classes.companyInfo}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <Hidden smDown implementation="css">
                <div className={classes.copyrightContainer}>
                  <SocialLinks classes={classes} socialLinks={social.links} />
                  <Copyright classes={classes} company={company} />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div className={classes.copyrightContainerMobile}>
                  <Copyright classes={classes} company={company} />
                </div>
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </CustomContainer>
    </div>
  )
}

export default Footer
