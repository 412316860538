import React from "react"
import { CurrentPageContext } from "../../../../common/context/page"
import { SitePagesContext } from "../../../../common/context/pages"
import { PrismicSEO } from "../../seo"

interface PrismicPageProviderProps {
  children: React.ReactNode
  page: any
}

export const PrismicPage = ({ children, page }: PrismicPageProviderProps) => {
  const pages = React.useContext(SitePagesContext)
  const pageData = pages.getPage(page.prismicId)

  return (
    <CurrentPageContext.Provider
      value={{
        id: page.prismicId,
        lang: page.lang,
        alternatePages:
          pageData?.alternatePages.map((x) => ({
            id: x.id,
            lang: x.lang,
            path: pages.getPagePath(x.id) ?? "",
          })) ?? [],
      }}
    >
      {children}
      <PrismicSEO node={page} />
    </CurrentPageContext.Provider>
  )
}
