import React from "react"

interface LanguageContextData {
  localeId: string
}

const LanguageContext = React.createContext<LanguageContextData>(
  {} as LanguageContextData
)

export default LanguageContext

interface LanguageProviderProps {
  language: string
  children: React.ReactNode
}

export const LanguageProvider = ({
  language,
  children,
}: LanguageProviderProps) => {
  return (
    <LanguageContext.Provider value={{ localeId: language }}>
      {children}
    </LanguageContext.Provider>
  )
}
