import React from "react"
import CustomTypography, {
  CustomTypographyProps,
} from "../../../atoms/CustomTypography"
import { useTheme } from "@material-ui/core"
import { processHtmlValue } from "./processors/defaultHtmlProcessor"
import RawContent from "./processors/rawContentProcessor"
import {
  Placeholders,
  RichText,
  RichTextObject,
} from "../../../../../types/contents"

interface Props extends CustomTypographyProps {
  value?: RichText
  placeholders?: Placeholders
  invalidTags?: string[]
  customizeHtml?: boolean
  margins?: boolean
}

const getHtmlFieldValue = (value: RichText) => {
  /* eslint-disable */
  if (value?.hasOwnProperty("html")) {
    return (value as RichTextObject).html
  }

  /* eslint-disable */
  if (value?.hasOwnProperty("text")) {
    return (value as RichTextObject).text
  }

  if (typeof value === "string") {
    return value.toString()
  }

  if (!React.isValidElement(value)) {
    return value?.toString() ?? ""
  }
}

const getRawTagsValue = (value: any) => {
  /* eslint-disable */
  if (value.hasOwnProperty("raw")) {
    return value.raw
  }
}

const HtmlRichTextField = ({
  value,
  placeholders,
  invalidHtmlTags,
  variant,
  customizeHtml,
  ...typographyProps
}: any) => {
  const theme = useTheme()
  const htmlValue = processHtmlValue(value, {
    placeholders,
    invalidHtmlTags,
    theme,
    customizeHtml,
  })
  return (
    <CustomTypography
      variant={variant}
      innerHtml={htmlValue}
      {...typographyProps}
    />
  )
}

// const preProcessRawValue = (
//   value: string,
//   theme: Theme,
//   lastElement: boolean
// ) => {
//   const terms = value.split(" ")
//   return terms
//     .map((x, index) =>
//       customizeHtml(x, index === terms.length - 1 && lastElement, theme)
//     )
//     .join(" ")
// }

// const preProcessRaw = (value: any, theme: Theme) => {
//   if (typeof value === "string") {
//     return preProcessRawValue(value, theme, false)
//   }

//   if (Array.isArray(value)) {
//     const processedValue = jsonClone(value)
//     processedValue.forEach((row, index) => {
//       if (
//         row.hasOwnProperty("text") &&
//         (!row.hasOwnProperty("spans") || row.spans.length === 0) //in this case the text already have html customization inside the cms
//       ) {
//         row.text = preProcessRawValue(
//           row.text,
//           theme,
//           index === processedValue.length - 1
//         )
//       }
//     })
//     return processedValue
//   }

//   return value
// }

const isEmptyRawValue = (value: any) =>
  value.length === 0 || (value.length === 1 && value[0].text === "")

const isEmptyHtmlValue = (value: string) => value === ""

export const isEmtyRichText = (value: any) => {
  if (!value) {
    return true
  }
  return (
    (value.raw && isEmptyRawValue(value.raw)) ||
    (value.html && isEmptyHtmlValue(value.html))
  )
}

const RichTextField = ({
  value,
  variant,
  invalidTags,
  placeholders,
  margins,
  ...typographyProps
}: Props) => {
  const getInvalidTags = () =>
    variant !== undefined && invalidTags ? invalidTags : []

  if (!value) {
    return <></>
  }

  const rawValue = getRawTagsValue(value)
  if (rawValue) {
    return (
      <RawContent
        value={rawValue}
        variant={variant}
        placeholders={placeholders}
        margins={margins}
        {...typographyProps}
      />
    )
  }

  const htmlValue = getHtmlFieldValue(value)
  if (htmlValue) {
    return (
      <HtmlRichTextField
        value={htmlValue}
        invalidHtmlTags={getInvalidTags()}
        variant={variant}
        placeholders={placeholders}
        {...typographyProps}
      />
    )
  }

  return <></>
}

RichTextField.defaultProps = {
  invalidTags: ["p", "h1", "h2", "h3", "h4", "h5", "h6"],
}

export { RichTextField }
