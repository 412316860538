import React from "react"
import Footer from "../../../ui/organisms/Footer"

interface Props {
  transparent: boolean
  data: any
}

const FooterLayout = ({ data, transparent }: Props) => {
  return (
    <Footer
      data={data}
      backgroundColor={!transparent ? "#080506" : undefined}
      transparent={transparent}
    />
  )
}

export default FooterLayout
