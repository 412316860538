import { LayoutQuery_allPrismicLayout_nodes as PrismicLayoutNode } from "../__generated__/LayoutQuery"
import { LayoutData } from "../types/pages"

export const toLayoutData = (node: PrismicLayoutNode): LayoutData => ({
  header: {
    logoUrl: node.data?.header_logo?.fluid?.src ?? "",
    links:
      node.data?.header_links?.map((x) => ({
        name: x?.header_link_name?.text ?? "",
        link: x?.header_link_target,
      })) ?? [],
  },
  footer: {
    linkSection: {
      items:
        node.data?.footer_links?.map((x) => ({
          label: x?.footer_link_title?.text ?? "",
          link: x?.footer_link_target,
        })) ?? [],
    },
    social: {
      links: [
        {
          icon: node.data?.twitter_logo,
          url: node.data?.twitter_link?.url ?? "",
        },
        {
          icon: node.data?.facebook_logo,
          url: node.data?.facebook_link?.url ?? "",
        },
        {
          icon: node.data?.linkedin_logo,
          url: node.data?.linkedin_link?.url ?? "",
        },
        {
          icon: node.data?.instagram_logo,
          url: node.data?.instagram_link?.url ?? "",
        },
      ].filter((x) => x.url !== ""),
    },
    company: {
      logo: node.data?.footer_logo,
      name: node.data?.company_name?.text ?? "",
      info: node.data?.company_data,
      copyright: node.data?.footer_copyright,
    },
  },
  cookies: {
    message: node.data?.cookie_message ?? "",
    title: node.data?.cookie_title ?? "",
    acceptLabel: node.data?.cookie_accept_label?.text ?? "",
  },
})
