import React from "react"
import { SEO } from "../../../common/seo"

interface Props {
  node: any
}

const PrismicSEO = ({ node }: Props) => {
  return (
    <SEO
      lang={node.lang as string}
      title={node.data?.seo_title?.text ?? node.data?.title?.text ?? ""}
      description={node.data?.seo_description?.text ?? ""}
    />
  )
}

export default PrismicSEO
