import React from "react"
import ScrollAnimation from "react-animate-on-scroll"

interface AnimationProps {
  delay?: number
  children: React.ReactNode
  offset?: number
  h100?: boolean
}

export const FadeIn = ({ h100, children, ...other }: AnimationProps) => (
  <ScrollAnimation
    animateIn="fadeIn"
    {...other}
    animateOnce
    style={{ height: h100 ? "100%" : undefined }}
  >
    {children}
  </ScrollAnimation>
)
