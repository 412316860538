import React from "react"
import { Link } from "gatsby"
import { LinkValue } from "../../../../../types/contents"
import { SitePagesContext } from "../../../../../root/common/context/pages"

interface LinkFieldProps {
  value: LinkValue
  className?: string
  activeClassName?: string
  children?: React.ReactNode
}

const InternalLink = ({
  value,
  className,
  activeClassName,
  children,
}: LinkFieldProps) => {
  const sitePages = React.useContext(SitePagesContext)
  const path = sitePages.getPagePath(value.id)
  return (
    <>
      {path ? (
        <Link to={path} className={className} activeClassName={activeClassName}>
          {children}
        </Link>
      ) : undefined}
    </>
  )
}
const ExternalLink = ({ value, className, children }: LinkFieldProps) => {
  return (
    <a className={className} href={value.url} target={value.target}>
      {children}
    </a>
  )
}

const LinkField = ({
  value,
  className,
  activeClassName,
  children,
}: LinkFieldProps) => {
  if (!value) {
    return <></>
  }
  switch (value.link_type?.toLowerCase()) {
    case "document":
      return (
        <InternalLink
          value={value}
          className={className}
          activeClassName={activeClassName}
        >
          {children}
        </InternalLink>
      )
    case "media":
    case "web":
      return (
        <ExternalLink value={value} className={className}>
          {children}
        </ExternalLink>
      )
    default:
      return <></>
  }
}

export { LinkField }
