import { Link } from "gatsby"
import React, { useContext } from "react"
import { locales } from "../../../../config/i18n"
import { CurrentPageContext } from "../../../../root/common/context/page"

const getTargetLocale = (currentLang: string) =>
  currentLang === "it-it" ? "en-us" : "it-it"

interface Props {
  className?: string
  component?: (lang: string) => React.ReactNode
}

const LanguageSwitch = ({ className, component }: Props) => {
  const page = useContext(CurrentPageContext)
  if (!page) {
    return <></>
  }

  const targetLocaleId = getTargetLocale(page.lang)
  const targetLocaleData = locales.find((x) => x.id === targetLocaleId)

  const alternatePageUrl = page.alternatePages.find(
    (x) => x.lang === targetLocaleId
  )?.path

  if (!alternatePageUrl) {
    console.error(`Alternate page not found for ${page.id}`)
    return <></>
  }

  const displayName = targetLocaleData?.code.toUpperCase() ?? targetLocaleId

  return (
    <div className={className}>
      <Link to={alternatePageUrl}>
        {component ? component(displayName) : <>{displayName}</>}
      </Link>
    </div>
  )
}

export default LanguageSwitch
