import React from "react"
import { Typography, makeStyles } from "@material-ui/core"
import classNames from "classnames"
import {
  TextVariant,
  FontWeight,
  ThemeColor,
  ThemeColorVariant,
} from "../../../../types/theme-types"
import { getTextSize, getColor } from "../../../../utils/themeUtils"
import { fontWeights } from "../../../../themes/variables"

export interface CustomTypographyProps {
  component?: string
  color?: ThemeColor
  colorVariant?: ThemeColorVariant
  colorValue?: string
  variant?: TextVariant
  lineHeight?: TextVariant
  lineHeightValue?: string | number
  fontSize?: TextVariant
  fontSizeValue?: string
  weight?: FontWeight
  mb?: number
  mt?: number
  my?: number
  mx?: number
  mr?: number
  ml?: number
  pb?: number
  pt?: number
  py?: number
  px?: number
  pr?: number
  pl?: number
  className?: string
  capitalize?: boolean
  uppercase?: boolean
  lowercase?: boolean
  inheritStyles?: boolean
  textCenter?: boolean
  textRight?: boolean
  textLeft?: boolean
  ellipsis?: boolean
}

interface Props extends CustomTypographyProps {
  children?: React.ReactNode
  innerHtml?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: ({ lineHeight, lineHeightValue }: any) =>
      lineHeight ? getTextSize(lineHeight) : lineHeightValue,
    fontSize: ({ fontSize, fontSizeValue }: any) =>
      fontSize ? getTextSize(fontSize) : fontSizeValue,
    marginBottom: ({ marginBottom }: any) =>
      marginBottom ? theme.spacing(marginBottom) : undefined,
    marginTop: ({ marginTop }: any) =>
      marginTop ? theme.spacing(marginTop) : undefined,
    marginLeft: ({ marginLeft }: any) =>
      marginLeft ? theme.spacing(marginLeft) : undefined,
    marginRight: ({ marginRight }: any) =>
      marginRight ? theme.spacing(marginRight) : undefined,
    paddingBottom: ({ paddingBottom }: any) =>
      paddingBottom ? theme.spacing(paddingBottom) : undefined,
    paddingTop: ({ paddingTop }: any) =>
      paddingTop ? theme.spacing(paddingTop) : undefined,
    paddingLeft: ({ paddingLeft }: any) =>
      paddingLeft ? theme.spacing(paddingLeft) : undefined,
    paddingRight: ({ paddingRight }: any) =>
      paddingRight ? theme.spacing(paddingRight) : undefined,
    color: ({ color, colorVariant, colorValue }: any) =>
      colorValue ?? getColor(theme, color, colorVariant),
  },
  uppercase: {
    textTransform: "uppercase",
  },
  lowercase: {
    textTransform: "lowercase",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  fontWeightLight: {
    fontWeight: fontWeights.light,
  },
  fontWeightLighter: {
    fontWeight: fontWeights.lighter,
  },
  fontWeightNormal: {
    fontWeight: fontWeights.regular,
  },
  fontWeightMedium: {
    fontWeight: fontWeights.medium,
  },
  fontWeightBold: {
    fontWeight: fontWeights.bold,
  },
  inherit: {
    color: "inherit",
    lineHeight: "inherit",
    fontSize: "inherit",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textLeft: {
    textAlign: "right",
  },
  ellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}))

const CustomTypography = ({
  component,
  children,
  innerHtml,
  color,
  colorVariant,
  colorValue,
  weight,
  lineHeight,
  lineHeightValue,
  fontSize,
  fontSizeValue,
  variant,
  capitalize,
  uppercase,
  lowercase,
  mb,
  mt,
  my,
  mr,
  mx,
  ml,
  pb,
  pt,
  py,
  px,
  pr,
  pl,
  className,
  inheritStyles,
  textCenter,
  textLeft,
  textRight,
  ellipsis,
  ...other
}: Props) => {
  const classes = useStyles({
    color,
    colorVariant,
    colorValue,
    lineHeight,
    lineHeightValue,
    fontSize,
    fontSizeValue,
    marginBottom: mb ?? my,
    marginTop: mt ?? my,
    marginRight: mr ?? mx,
    marginLeft: ml ?? mx,
    paddingBottom: pb ?? py,
    paddingTop: pt ?? py,
    paddingRight: pr ?? px,
    paddingLeft: pl ?? px,
  })
  const elementClasses = classNames(className, {
    [classes.fontWeightLighter]: weight === "lighter",
    [classes.fontWeightLight]: weight === "light",
    [classes.fontWeightNormal]: weight === "normal",
    [classes.fontWeightMedium]: weight === "medium",
    [classes.fontWeightBold]: weight === "bold",
    [classes.capitalize]: capitalize,
    [classes.uppercase]: uppercase,
    [classes.lowercase]: lowercase,
    [classes.inherit]: inheritStyles,
    [classes.textCenter]: textCenter,
    [classes.textLeft]: textLeft,
    [classes.textRight]: textRight,
    [classes.ellipsis]: ellipsis,
  })
  return (
    <>
      {innerHtml ? (
        <Typography
          classes={{ root: classes.root }}
          component={component as any}
          variant={variant}
          className={elementClasses}
          dangerouslySetInnerHTML={{
            __html: innerHtml,
          }}
          {...other}
        ></Typography>
      ) : (
        <Typography
          variant={variant}
          classes={{ root: classes.root }}
          component={component as any}
          className={elementClasses}
          {...other}
        >
          {children}
        </Typography>
      )}
    </>
  )
}

export default CustomTypography
