import {
  Theme,
  fade,
  darken,
  lighten,
  Size,
  decomposeColor,
  recomposeColor,
  ColorFormat,
  ColorObject,
} from "@material-ui/core"
import {
  ThemeColor,
  ThemeColorVariant,
  TextVariant,
  Spacing,
  FontWeight,
} from "../types/theme-types"
import { fontSizes, inputFontSizes, labelFontSizes } from "../themes/variables"

const defaultColorVariant = "main" as ThemeColorVariant

export const getGreyBackgroundColor = (theme: Theme) => theme.palette.grey[200]

export const getPrimaryColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.primary.main
    case "dark":
      return theme.palette.primary.dark
    case "light":
      return theme.palette.primary.light
  }
}

export const getSecondaryColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.secondary.main
    case "dark":
      return theme.palette.secondary.dark
    case "light":
      return theme.palette.secondary.light
  }
}

export const getInfoColor = (theme: Theme, colorVariant: ThemeColorVariant) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.info.main
    case "dark":
      return theme.palette.info.dark
    case "light":
      return theme.palette.info.light
  }
}

export const getSuccessColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.success.main
    case "dark":
      return theme.palette.success.dark
    case "light":
      return theme.palette.success.light
  }
}

export const getWarningColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.warning.main
    case "dark":
      return theme.palette.warning.dark
    case "light":
      return theme.palette.warning.light
  }
}

export const getErrorColor = (
  theme: Theme,
  colorVariant: ThemeColorVariant
) => {
  switch (colorVariant) {
    case "main":
      return theme.palette.error.main
    case "dark":
      return theme.palette.error.dark
    case "light":
      return theme.palette.error.light
  }
}

export const getColor = (
  theme: Theme,
  color: ThemeColor,
  colorVariant = defaultColorVariant
) => {
  switch (color) {
    case "primary":
      return getPrimaryColor(theme, colorVariant)
    case "secondary":
      return getSecondaryColor(theme, colorVariant)
    case "info":
      return getInfoColor(theme, colorVariant)
    case "success":
      return getSuccessColor(theme, colorVariant)
    case "warning":
      return getWarningColor(theme, colorVariant)
    case "error":
      return getErrorColor(theme, colorVariant)
    case "neutral":
      return theme.palette.action.active
    case "disabled":
      return theme.palette.action.disabled
    case "white":
      return theme.palette.common.white
    case "black":
      return theme.palette.common.black
    case "inherit":
      return "inherit"
    case "transparent":
      return "rgba(0,0,0,0)"
  }
}

export const getColorContrast = (theme: Theme, color: ThemeColor) => {
  switch (color) {
    case "primary":
      return theme.palette.primary.contrastText
    case "secondary":
      return theme.palette.secondary.contrastText
    case "info":
      return theme.palette.info.contrastText
    case "success":
      return theme.palette.success.contrastText
    case "warning":
      return theme.palette.warning.contrastText
    case "error":
      return theme.palette.error.contrastText
    case "neutral":
      return theme.palette.action.disabled
    case "disabled":
      return theme.palette.action.active
    case "white":
      return theme.palette.common.black
    case "black":
      return theme.palette.common.white
    case "inherit":
      return "inherit"
    case "transparent":
      return "rgba(0,0,0,0)"
  }
}

export const getFadedColor = (
  theme: Theme,
  color: ThemeColor,
  fadeValue: number,
  colorVariant = defaultColorVariant
) => {
  return fade(getColor(theme, color, colorVariant), fadeValue)
}

export const getDarkenColor = (
  theme: Theme,
  color: ThemeColor,
  coefficient: number,
  colorVariant = defaultColorVariant
) => {
  return darken(getColor(theme, color, colorVariant), coefficient)
}

export const getLightenColor = (
  theme: Theme,
  color: ThemeColor,
  coefficient: number,
  colorVariant = defaultColorVariant
) => {
  return lighten(getColor(theme, color, colorVariant), coefficient)
}

export const getThemeColor = (
  theme: Theme,
  color: ThemeColor,
  negative?: boolean
) => {
  return negative ? getColorContrast(theme, color) : getColor(theme, color)
}

export const getAlphaColor = (
  theme: Theme,
  color: ThemeColor,
  alpha: number,
  colorVariant = defaultColorVariant,
  fadeValue?: number
) => {
  const mainColor = fadeValue
    ? getFadedColor(theme, color, fadeValue, colorVariant)
    : getColor(theme, color, colorVariant)
  const raw = decomposeColor(mainColor)
  const rawAlpha = {
    type: "rgba" as ColorFormat,
    values: [...raw.values, alpha],
  } as ColorObject
  return recomposeColor(rawAlpha)
}

export const getTextSize = (size: TextVariant) => {
  switch (size) {
    case "body1":
      return fontSizes.body1
    case "body2":
      return fontSizes.body2
    case "h1":
      return fontSizes.h1
    case "h2":
      return fontSizes.h2
    case "h3":
      return fontSizes.h3
    case "h4":
      return fontSizes.h4
    case "h5":
      return fontSizes.h5
    case "subtitle1":
      return fontSizes.subtitle1
    case "subtitle2":
      return fontSizes.subtitle2
    case "overline":
      return fontSizes.overline
    default:
      return fontSizes.body1
  }
}

export const getInputFontSize = (size: Size) => inputFontSizes[size]
export const getLabelFontSize = (size: Size) => labelFontSizes[size]

export const getInputColor = (
  theme: Theme,
  color: ThemeColor,
  negative?: boolean
) => {
  switch (color) {
    case "neutral":
      return getThemeColor(theme, "black", negative)
    default:
      return getThemeColor(theme, color, negative)
  }
}

export const getSpacing = (theme: Theme, spacing: Spacing) => {
  switch (spacing) {
    case "xs":
      return theme.spacing(1)
    case "sm":
      return theme.spacing(2)
    case "md":
      return theme.spacing(3)
    case "lg":
      return theme.spacing(4)
    case "xl":
      return theme.spacing(5)
    default:
      return 0
  }
}

export const getFontWeight = (weight: FontWeight) => {
  switch (weight) {
    case "bold":
      return 900
    case "medium":
      return 700
    case "light":
      return 300
    case "lighter":
      return 100
    default:
      return "normal"
  }
}

export const whenMobile = (theme: Theme) => theme.breakpoints.down("sm")
export const whenDesktop = (theme: Theme) => theme.breakpoints.up("md")
