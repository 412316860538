import React from "react"
import { makeStyles, Grid, Theme } from "@material-ui/core"
import { HeaderData } from "../../../../types/pages"
import { LinkField } from "../../../ui/molecules/Fields"
import { ThemeColor } from "../../../../types/theme-types"
import { fontSizes } from "../../../../themes/variables"
import LanguageSwitch from "../LanguageSwitch"
import CustomTypography from "../../../ui/atoms/CustomTypography"

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "space-between",
    position: ({ inline }: any) => (!inline ? "absolute" : undefined),
    top: 0,
    left: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    minHeight: theme.spacing(8),
    zIndex: 1,
    backgroundColor: ({ backgroundColor }: any) => backgroundColor,

    // [whenDesktop(theme)]: {
    //   paddingTop: ({ backgroundColor }: any) =>
    //     backgroundColor === undefined || backgroundColor === "transparent"
    //       ? theme.spacing(4)
    //       : undefined,
    // },
  },
  header: {
    padding: theme.spacing(1, 2),
  },
  linkItem: {
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "0.96px",
    marginRight: theme.spacing(2),

    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(4),
    },

    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(6),
    },

    borderBottom: "1px solid transparent",

    "&:hover": {
      borderBottom: `1px solid ${theme.palette.common.white}`,
    },
  },
  lang: {
    marginRight: theme.spacing(4),
    fontSize: fontSizes.subtitle2,

    "&:hover": {
      fontWeight: 300,
    },
  },
  logoItem: {
    paddingLeft: theme.spacing(8),
  },
  linksContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(8),
  },
  links: {
    display: "flex",
    justifyContent: "flex-end",
    color: theme.palette.common.white,
  },
  logo: {
    width: "77px",
    height: "41px",
  },
  active: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
    fontWeight: "bold",
  },
}))

interface Props {
  data: HeaderData
  backgroundColor?: ThemeColor
  inline?: boolean
}

const Header = ({ data, backgroundColor, inline }: Props) => {
  const classes = useStyles({
    backgroundColor,
    inline,
  })
  return (
    <div className={classes.appbar}>
      <Grid container>
        <Grid item xs={6} className={classes.logoItem}>
          <a href="/">
            <img src={data.logoUrl} className={classes.logo} />
          </a>
        </Grid>
        <Grid item xs={6} className={classes.linksContainer}>
          <div className={classes.links}>
            {/* {data.links.map((item, index) => (
              <LinkField
                activeClassName={classes.active}
                className={classes.linkItem}
                value={item.link}
                key={index}
              >
                <RichTextField variant="subtitle2" value={item.name} />
              </LinkField>
            ))} */}
            <LanguageSwitch
              component={(lang) => (
                <CustomTypography
                  variant="subtitle2"
                  weight="lighter"
                  className={classes.lang}
                >
                  {lang}
                </CustomTypography>
              )}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Header
