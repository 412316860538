import { Placeholders } from "../types/contents"

const matchTagsRegex = (tags: string[]) =>
  new RegExp(`<(${tags.map((x) => `${x}|/${x}`).join("|")})[^>]{0,}>`, "ig")

const cleanHtml = (value: string, invalidTags: string[]) => {
  return value.replace(matchTagsRegex(invalidTags), "")
}

const replacePlaceholders = (value: string, placeholders: Placeholders) => {
  let text = value
  for (const key in placeholders) {
    text = text.replace(`{${key.toUpperCase()}}`, placeholders[key] ?? "")
  }
  return text
}

const processHtml = (
  value: string | undefined,
  processor: (term: string, first: boolean, last: boolean) => string
) => {
  const terms = value?.split(" ") ?? []
  return terms
    .map((x, index) => processor(x, index === 0, index === terms.length - 1))
    .join(" ")
}

export { cleanHtml, replacePlaceholders, processHtml }
