import { Theme } from "@material-ui/core"
import {
  processHtml,
  cleanHtml,
  replacePlaceholders,
} from "../../../../../../utils/htmlUtils"
import { Placeholders } from "../../../../../../types/contents"

export const customizeHtml = (value: string, last: boolean, theme: Theme) => {
  return last
    ? `<span style="color: ${theme.palette.primary.main}; text-transform: uppercase">${value}</span>`
    : value
}

interface ProcessHtmlOptions {
  placeholders: Placeholders | undefined
  theme: Theme
  invalidHtmlTags: string[]
  customizeHtml: boolean
}

export const processHtmlValue = (
  value: string,
  options: ProcessHtmlOptions
) => {
  try {
    const valueWithPlaceholders = replacePlaceholders(
      value,
      options.placeholders ?? {}
    )
    return processHtml(
      cleanHtml(valueWithPlaceholders, options.invalidHtmlTags),
      (x, y, last) =>
        options.customizeHtml ? customizeHtml(x, last, options.theme) : x
    )
  } catch (e) {
    console.error(`Error processing html value ${value}`)
    console.error(e)
    return ""
  }
}
