import React from "react"
import { Drawer, Fab, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import CloseIcon from "@material-ui/icons/Close"
import { HeaderData } from "../../../../types/pages"
import { RichTextField } from "../../../ui/molecules/Fields/RichTextField"
import { LinkField } from "../../../ui/molecules/Fields"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.common.white,

    "& .MuiDrawer-paper": {
      width: "100%",
      backgroundColor: "#121711",
    },
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  logo: {
    margin: theme.spacing(2),
    width: 56,
  },
  closeFab: {
    margin: theme.spacing(1),
    backgroundColor: "transparent !important",
    color: theme.palette.common.white,
  },
  linkItem: {
    margin: theme.spacing(2, 1),

    "& .MuiTypography-root": {
      color: theme.palette.common.white,
      textTransform: "uppercase",
    },
  },
  active: {},
}))

interface Props {
  data: HeaderData
  open: boolean
  onClose: () => void
}

const MobileHeaderDrawer = ({ data, open, onClose }: Props) => {
  const classes = useStyles()
  return (
    <Drawer className={classes.root} open={open} anchor="right">
      <div className={classes.head}>
        <div>
          <img src={data.logoUrl} className={classes.logo} />
        </div>
        <div>
          <Fab size="small" onClick={onClose} className={classes.closeFab}>
            <CloseIcon />
          </Fab>
        </div>
      </div>
      <div className={classes.content}>
        {data.links.map((item, index) => (
          <LinkField
            activeClassName={classes.active}
            className={classes.linkItem}
            value={item.link}
            key={index}
          >
            <RichTextField variant="h5" value={item.name} />
          </LinkField>
        ))}
      </div>
    </Drawer>
  )
}

export default MobileHeaderDrawer
