import React from "react"
import { makeStyles } from "@material-ui/styles"
import MenuIcon from "@material-ui/icons/Menu"
import { HeaderData } from "../../../../types/pages"
import { Fab, Theme } from "@material-ui/core"
import MobileHeaderDrawer from "./MobileHeaderDrawer"
import { LinkField } from "../../../ui/molecules/Fields"
import { ThemeColor } from "../../../../types/theme-types"
import LanguageSwitch from "../LanguageSwitch"
import CustomTypography from "../../../ui/atoms/CustomTypography"

interface Props {
  data: HeaderData
  backgroundColor?: ThemeColor
  inline?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    position: ({ inline }: any) => (!inline ? "absolute" : undefined),
    top: 0,
    left: 0,
    minHeight: theme.spacing(5),
    zIndex: 1,
    backgroundColor: ({ backgroundColor }: any) => backgroundColor,
  },
  logoContainer: {
    margin: theme.spacing(2),
  },
  logo: {
    width: 56,
  },
  menuFab: {
    margin: theme.spacing(1),
    backgroundColor: "transparent ! important",
    color: "white",
  },
  menuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  lang: {},
}))

const MobileHeader = ({ data, backgroundColor, inline }: Props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const classes = useStyles({
    backgroundColor,
    inline,
  })
  return (
    <>
      <div className={classes.root}>
        <div className={classes.logoContainer}>
          <LinkField value={data.links?.[0].link}>
            <img src={data.logoUrl} className={classes.logo} />
          </LinkField>
        </div>
        <div className={classes.menuContainer}>
          <LanguageSwitch
            component={(lang) => (
              <CustomTypography
                variant="subtitle2"
                weight="lighter"
                color="white"
                className={classes.lang}
              >
                {lang}
              </CustomTypography>
            )}
          />
          <Fab
            className={classes.menuFab}
            size="small"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </Fab>
        </div>
      </div>
      <MobileHeaderDrawer
        data={data}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  )
}

export default MobileHeader
