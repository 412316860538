import React from "react"
import Footer from "./components/Footer"
import Header from "./components/Header"
import { LayoutContext } from "../../context/contents/layoutContext"
import MobileHeader from "./components/MobileHeader"
import { ThemeColor } from "../../types/theme-types"
import { Hidden } from "@material-ui/core"
import CookieBar from "./components/CookieBar"
import { RichTextField } from "../ui/molecules/Fields"

interface Props {
  children: React.ReactNode
  transparentFooter?: boolean
  headerBackground?: ThemeColor
  headerInline?: boolean
}

const DefaultLayout = ({
  children,
  transparentFooter,
  headerBackground,
  headerInline,
}: Props) => {
  const { header, footer, cookies } = React.useContext(LayoutContext)
  return (
    <div>
      <Hidden smUp implementation="css">
        <MobileHeader
          data={header}
          backgroundColor={headerBackground}
          inline={headerInline}
        />
      </Hidden>
      <Hidden xsDown implementation="css">
        <Header
          data={header}
          backgroundColor={headerBackground}
          inline={headerInline}
        />
      </Hidden>
      <div>{children}</div>
      <Footer transparent={transparentFooter ?? false} data={footer} />
      <CookieBar
        cookieName="wigoCookieConsent"
        message={<RichTextField value={cookies.message} />}
        acceptButtonLabel={cookies.acceptLabel}
      />
    </div>
  )
}

export default DefaultLayout
