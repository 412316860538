import { Container, makeStyles, Theme } from "@material-ui/core"
import classNames from "classnames"
import React from "react"

type BackgroundColor = "primary" | "secondary"

interface Props {
  children?: any
  outerContainerClassName?: string
  className?: any
  backgroundColor?: BackgroundColor
  grayBackground?: boolean
  grayBackgroundUpSm?: boolean
  grayBackgroundDownSm?: boolean
  disableGutters?: boolean
  disableGuttersDown?: "xs" | "sm" | "md" | "lg"
  disableGuttersUp?: "sm" | "md" | "lg" | "xl"
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
  pt?: number
  pb?: number
  py?: number
  mt?: number
  mb?: number
  my?: number
  flex1?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  secondaryColorPrimary: {
    backgroundColor: theme.palette.secondary.main,
  },
  disableGuttersUpSm: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersUpMd: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersUpLg: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersUpXl: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersDownXs: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersDownSm: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersDownMd: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  disableGuttersDownLg: {
    [theme.breakpoints.down("lg")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  root: {
    paddingTop: (props: any) => theme.spacing(props.paddingTop),
    paddingBottom: (props: any) => theme.spacing(props.paddingBottom),
    display: "flex",
    flexDirection: "column",
    flex: 1,

    [theme.breakpoints.up("md")]: {
      // maxWidth: 720,
      maxWidth: 960,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1140,
    },
    // [theme.breakpoints.up("xl")]: {
    //   maxWidth: 1140,
    // },
  },
  grayBackground: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  grayBackgroundUpSm: {
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  grayBackgroundDownSm: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
  flex1: {
    flex: 1,
  },
}))

const CustomContainer = ({
  children,
  className,
  outerContainerClassName,
  backgroundColor,
  grayBackground,
  grayBackgroundUpSm,
  grayBackgroundDownSm,
  disableGutters,
  maxWidth,
  disableGuttersDown,
  disableGuttersUp,
  pt,
  pb,
  py,
  mt,
  mb,
  my,
  flex1,
}: Props) => {
  const classes = useStyles({
    paddingTop: py ?? pt ?? 0,
    paddingBottom: py ?? pb ?? 0,
    marginTop: my ?? mt ?? 0,
    marginBottom: my ?? mb ?? 0,
    flex1,
  })
  const containerClasses = classNames(className, classes.root, {
    [classes.backgroundColorPrimary]: backgroundColor === "primary",
    [classes.secondaryColorPrimary]: backgroundColor === "secondary",
    [classes.disableGuttersUpSm]: disableGuttersUp === "sm",
    [classes.disableGuttersUpMd]: disableGuttersUp === "md",
    [classes.disableGuttersUpLg]: disableGuttersUp === "lg",
    [classes.disableGuttersUpXl]: disableGuttersUp === "xl",
    [classes.disableGuttersDownXs]: disableGuttersDown === "xs",
    [classes.disableGuttersDownSm]: disableGuttersDown === "sm",
    [classes.disableGuttersDownMd]: disableGuttersDown === "md",
    [classes.disableGuttersDownLg]: disableGuttersDown === "lg",
    [classes.flex1]: flex1 === true,
  })
  return (
    <div
      className={classNames(
        classes.outerContainer,
        {
          [classes.grayBackground]: grayBackground,
          [classes.grayBackgroundUpSm]: grayBackgroundUpSm,
          [classes.grayBackgroundDownSm]: grayBackgroundDownSm,
        },
        outerContainerClassName
      )}
    >
      <Container
        className={containerClasses}
        maxWidth={maxWidth}
        disableGutters={disableGutters}
      >
        {children}
      </Container>
    </div>
  )
}

CustomContainer.defaultProps = {
  maxWidth: "lg",
}

export default CustomContainer
