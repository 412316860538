import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { LayoutQuery } from "../../__generated__/LayoutQuery"
import { LayoutData } from "../../types/pages"
import { toLayoutData } from "../../converters/layout"
import LanguageContext from "../language"

export const LayoutContext = React.createContext<LayoutData>({} as LayoutData)

export const LayoutContextProvider = ({ children }: any) => {
  const language = React.useContext(LanguageContext)
  const data = useStaticQuery<LayoutQuery>(graphql`
    query LayoutQuery {
      allPrismicLayout {
        nodes {
          lang
          data {
            header_logo {
              fluid {
                ...ImageFluid
              }
            }
            header_links {
              header_link_name {
                text
              }
              header_link_target {
                id
                target
                link_type
              }
            }
            footer_logo {
              fluid {
                ...ImageFluid
              }
            }
            footer_links {
              footer_link_title {
                text
              }
              footer_link_target {
                id
                target
                link_type
              }
            }
            footer_copyright {
              html
            }
            company_name {
              text
            }
            company_data {
              raw
            }
            facebook_link {
              url
              type
              target
              id
            }
            instagram_link {
              url
              type
              target
              id
            }
            linkedin_link {
              url
              type
              id
              target
            }
            twitter_link {
              url
              target
              type
              id
            }
            twitter_logo {
              fluid {
                ...ImageFluid
              }
            }
            linkedin_logo {
              fluid {
                ...ImageFluid
              }
            }
            instagram_logo {
              fluid {
                ...ImageFluid
              }
            }
            facebook_logo {
              fluid {
                ...ImageFluid
              }
            }
            cookie_title {
              text
            }
            cookie_message {
              raw
            }
            cookie_accept_label {
              text
            }
          }
        }
      }
    }
  `)

  const layoutItem = data.allPrismicLayout.nodes.find(
    (x) => x.lang === language.localeId
  )

  if (!layoutItem) {
    return <></>
  }

  return (
    <LayoutContext.Provider value={toLayoutData(layoutItem)}>
      {children}
    </LayoutContext.Provider>
  )
}
